import React from "react";

import all from "../../assets/all3.jpg";
import Container from "../../components/Container/container";
import CTA from "../../components/CTA/cta";
import "./faq.scss";

const questions = [
	{
		q: `What are Tearribles?`,
		a: `Tearribles are interactive toys, designed to cater to dogs’ prey drive. They contain special pocket compartments which house detachable limbs and tail. Tearribles allow your dog to experience the satisfaction of “killing their prey” while keeping them mentally and physically stimulated.`,
	},
	{
		q: `What are interactive toys?`,
		a: `Interactive dog toys are toys designed to challenge your dog’s problem-solving skills, provide them with mental stimulation, and strengthen your bond through play. Just fifteen minutes of mental stimulation is equivalent to twice that time of running, and is a great way to calm a high-energy dog. Interactive toys have the highest propensity to provide you with endless training opportunities, and often happen to be your dog’s favorite toys to play with (hint: tennis ball, for example, is an interactive toy).`,
	},
	{
		q: `My dog tears up plush toys in seconds- why would Tearribles be any different?`,
		a: (
			<>
				Tearribles are designed to help you stop that behavior, while
				providing your dog with an outlet to exercise their prey
				instincts. Dogs are smart, and if you introduce your Tearrible
				through an interactive game (tug and fetch for example), they
				quickly understand that Tearribles are your together toy. From
				that point on, your dog will stop trying to annihilate the toy,
				and will instead bring it to you to put back together when all
				limbs are off.
				<br />
				<br />
				Dogs chew non-food things for two reasons:
				<ol>
					<li>they are teething</li>
					<li>they are bored</li>
				</ol>
				Just be there to put the toy back together for them, and your
				dog will get to enjoy their Tearrible for a long time.
			</>
		),
	},
	{
		q: `Are Tearribles chew toys/indestructible toys?`,
		a: (
			<>
				<span style={{ fontWeight: "bold" }}>
					No. Dog teeth, no matter how small, are built to crush bones
					and tear tendons
				</span>{" "}
				- there is no material (safe to be in your dog’s mouth) that
				your dog cannot chew through. <br />
				<br />
				The outside of Tearribles is made of Velboa (the plush-like
				fabric used to make blankets), which is then lined with mesh for
				added support. The limbs are attached to the toy via
				industrial-strength velcro, which provides just enough
				resistance to give your dog the satisfaction of “killing their
				victim,” and allows you to put the toy right back together for
				continuous play.
			</>
		),
	},
	{
		q: `What size Tearrible should I get for my dog?`,
		a: (
			<>
				Tearrible Tiny is 5” tall (approximately the height of a soda
				can), and is designed for dogs with very small mouths, like
				Chihuahuas, Shih-Tzus, Yorkies, and other miniature breeds.{" "}
				<br />
				<br />
				Tearrible Medium is 10” tall (approximately the height of a roll
				of paper towels) is designed for dogs that are on a bit tougher
				side, but are not necessarily small or large dogs. They are
				enjoyed by Dachshunds and pit bulls alike. <br />
				<br />
				Tearrible XL is 14” tall (approximately the height of a 2 liter
				bottle) and is designed for bigger dogs, with big mouths, who
				need a bit more of challenge (although we’ve seen a video of a
				very cute Corgi going at it as well).
			</>
		),
	},
	{
		q: `Do you ship internationally?`,
		a: (
			<>
				<p>
					We do! We ship to the EU, UK, Australia, Canada and South
					America. The shipping will auto-calculate based on the
					weight and size of your order, as well as the country you
					live in. It usually runs between $9 and $24 from what we've
					seen so far.
				</p>
				<p>
					For EU vat will be collected and calculated at the time of
					purchase. For AU there is no GST due at this time.
				</p>
				<p>
					Be advised that the cost of shipping to other countries may
					not include any applicable VAT or import duty taxes that may
					be imposed by your country, which would be due by you at the
					time of delivery.
				</p>
				<p>Please check with your local authorities for details.</p>
				<p>
					For Australian dog owners, we have a dedicated site for AU
					purchases. Please visit{" "}
					<a href="https://au.tearribles.com">au.tearribles.com</a>.
				</p>
			</>
		),
	},
	{
		q: `I would like to sell these at my pet store. How do I make it happen?`,
		a: (
			<>
				We work with independent brick and mortar stores all over US,
				and would love to hear from you. You can send us a little bit
				about your store here{" "}
				<a href="mailto:info@tearribles.com">info@tearribles.com</a>.
			</>
		),
	},
	{
		q: `Do you offer guarantee for your toys?`,
		a: `We stand behind our products and cover any defects of fabric, stitching, squeakers, and hook & loop (aka velcro). We do not cover any damage caused by misuse (chewing, gnawing, or ripping of individual parts). That being said, every toy we send out comes with a quick start guide that will help you properly introduce your Tearrible, and enjoy it for a long time. `,
	},
	{
		q: `Where are the toys made?`,
		a: `Tearribles are designed in US and made in China. Each batch is tested to meet or exceed children's toy standards in US, UK and EU.`,
	},
	{
		q: `Where are Tearribles shipped from and how long will it take to receive my order?`,
		a: `They are shipped from our warehouse in Florida. All orders ship within 48 hours of being placed, and take 5-7 days to arrive anywhere in the continental US unless you opt for a specific shipping service when you place your order.`,
	},
	{
		q: `I have a question not in FAQ; who can I ask?`,
		a: (
			<>
				We probably have the answer. Email us here{" "}
				<a href="mailto:support@tearribles.com">
					support@tearribles.com
				</a>
				.
			</>
		),
	},
];

const auQuestions = [
	{
		match: `Where are the toys made?`,
		q: `Where are the toys made?`,
		a: `Tearribles are designed in US and made in China. Each batch is tested to meet or exceed children's toy standards.`,
	},
	{
		match: `I would like to sell these at my pet store. How do I make it happen?`,
		q: `I would like to sell these at my pet store. How do I make it happen?`,
		a: (
			<>
				We currently do not work with wholesale accounts in AU as it is
				cost-prohibitive on a small scale. If you are a distributor and
				are interested in working with Tearribles, feel free to reach
				out at{" "}
				<a href="mailto:info@tearribles.com">info@tearribles.com </a>
				with your contact information and quantities/terms you are
				interested in working with. We currently do not accept any
				web-based retailers, only brick and mortar stores.
			</>
		),
	},
	{
		match: `Do you ship internationally?`,
		q: `Do you ship internationally?`,
		a: (
			<>
				Our Australian warehouse only ships within AU. For international
				shipments, please visit our US site{" "}
				<a href="https://www.tearribles.com">www.tearribles.com</a>.
			</>
		),
	},
	{
		match: `Where are Tearribles shipped from and how long will it take to receive my order?`,
		q: `Where are Tearribles shipped from and how long will it take to receive my order?`,
		a: `They are shipped via Sendle from our Sydney Fulfillment Warehouse. All orders ship within 48 hours of being placed, and take 5-7 days to arrive anywhere in AU unless you opt for a specific shipping service when you place your order.`,
	},
];

const renderQuestion = (questions) => {
	if (!Array.isArray(questions)) return null;

	return questions.map((question, index) => {
		return (
			<div className="faq__qa" key={`question__${index}`}>
				<h3 className="faq__question">{question.q}</h3>
				<p className="faq__answer">{question.a}</p>
			</div>
		);
	});
};

const FAQ = () => {
	const domain =
		process.env.GATSBY_MYSHOPIFY_URL || "tearribles.myshopify.com";

	if (domain === "tearriblesau.myshopify.com") {
		auQuestions.forEach((question) => {
			const questionIndex = questions.findIndex(
				(q) => q.q === question.match
			);

			if (questionIndex !== -1) {
				questions[questionIndex] = question;
			} else {
				questions.push(question);
			}
		});
	}

	return (
		<div className="faq">
			<Container>
				<h2>FAQ</h2>
				<h4>Got Questions?</h4>
				<div className="faq__wrapper">
					<div className="faq__images">
						<img
							className="faq__img"
							src={all}
							alt="All Tearribles"
						/>
						{/* <img className="faq__img" src={all} alt="All Tearribles" />
                    <img className="faq__img" src={all} alt="All Tearribles" /> */}
					</div>
					<div className="faq__text">
						{renderQuestion(questions)}
						<div className="faq__cta-container">
							<CTA to="/products" styles={{ width: "auto" }} />
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default FAQ;
