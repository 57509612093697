import React from 'react'

import FrequentlyAskedQuestions from '../components/FAQ/faq'

const FAQ = () => (
  <div>
    <FrequentlyAskedQuestions />
  </div>
)

export default FAQ
