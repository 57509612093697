import React from 'react';
import { Link } from 'gatsby';

import Arrow from '../../assets/arrow-alt.svg';
import './cta.scss';

const icons = {
    "arrow": <Arrow className="svg-arrow"/>,
    "back-arrow": <Arrow className="svg-back-arrow"/>
}

const CTA = ({ 
    to = "/",
    onClick = null, 
    styles = {},
    loading = false,
    title = "FETCH YOURS TODAY",
    inverted = false,
    disabled = false,
    secondary = false,
    prefixIcon = null,
    suffixIcon = null }) => {

        // Set prefix / suffix icons.
        const prefix = prefixIcon ? icons[prefixIcon] : null;
        const suffix = suffixIcon ? icons[suffixIcon] : null;

        // Set on click handler to check if button is disabled.
        const clickHandler = (event) => {
            if (typeof onClick !== 'function' || disabled) {
                return null;
            }
            onClick(event);
        }
        
        // Set cta classes.
        let ctaClasses = 'cta';

        if (disabled) {
            ctaClasses = `${ctaClasses} cta--disabled`;
        }

        if (inverted) {
            ctaClasses = `${ctaClasses} cta--inverted`;
        }

        if (secondary) {
            ctaClasses = `${ctaClasses} cta--secondary`;
        }

        const button = (
            <div className={ctaClasses} style={styles} onClick={clickHandler}>
                {!loading && prefix ? <div className="cta__icon-wrapper cta__prefix">{prefix}</div> : null}
                {!loading ? title : null}
                {!loading && suffix ? <div className="cta__icon-wrapper cta__suffix">{suffix}</div> : null}
                {loading && <div className="loader-3"><span></span></div>}
            </div>
        )

        return onClick
            ? button
            : <Link to={to} className="cta__link">{button}</Link>
}

export default CTA;